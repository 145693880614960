import * as React from "react";
import axios from "axios";

import { Box, SpeedDial, Dialog, Card } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import theme from "./theme";
import HeaderInfo from "./component/HeaderInfo";
import CardChiTieu from "./component/CardChiTieu";
import AddChiTieu from "./AddChiTieu";
import EditChiTieu from "./EditChiTieu";
import SelectDate from "./component/SelectDate";

import { LoginContext } from "./AppContext";

function ChiTieu() {
  const login = React.useContext(LoginContext);

  let date_now = new Date();
  let date_month_init = date_now.getMonth();
  let date_year_init = date_now.getFullYear();

  const [date_month, set_date_month] = React.useState(date_month_init);
  const [date_year, set_date_year] = React.useState(date_year_init);
  const [data, setData] = React.useState(null);
  const [open_dialog, set_open_dialog] = React.useState(false);
  const [open_dialog_edit, set_open_dialog_edit] = React.useState(false);
  const [data_edit, set_data_edit] = React.useState(null);

  const get_data = (month_input, year_input) => {
    var config = {
      method: "get",
      params: {
        date_year: year_input,
        date_month: month_input + 1,
      },
      url: process.env.REACT_APP_API_ENDPOINT + "transaction",
      headers: { Authorization: login.token },
    };

    axios(config)
      .then((data) => {
        let datax = data.data;

        if (datax.data_trong_thang != null) {
          datax.data_trong_thang = datax.data_trong_thang.sort((a, b) => {
            if (a.date_day == b.date_day) {
              return b.t_id - a.t_id;
            }
            return a.date_day < b.date_day ? 1 : -1;
          });
        }

        setData(datax);
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.hasOwnProperty("status")) {
            if (error.response.status == 401) {
              login.set_token(null);
            }
          }
        } else {
          console.log(error);
        }
      });
  };

  React.useEffect(() => {
    console.log(date_month, date_year);
    get_data(date_month, date_year);
  }, []);

  return data == null ? null : (
    <Box
      style={{ backgroundColor: theme.palette.color_map.gray, paddingTop: 70 }}
    >
      <SelectDate
        date_month={date_month}
        date_year={date_year}
        onChangeMonth={(newValue) => {
          set_date_month(newValue);
          get_data(newValue, date_year);
        }}
        onChangeYear={(e) => {
          set_date_year(e.target.value);
          get_data(date_month, e.target.value);
        }}
      />
      <HeaderInfo data={data.infomation} />
      {data.data_trong_thang == null ? (
        <Box style={{ height: "100vh" }}></Box>
      ) : (
        <CardChiTieu
          data={data.data_trong_thang}
          onClickEdit={(data) => {
            set_open_dialog_edit(true);
            set_data_edit(data);
          }}
        />
      )}

      <Box onClick={() => set_open_dialog(!open_dialog)}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={style_detail.add_icon}
          icon={<SpeedDialIcon />}
        ></SpeedDial>
      </Box>

      <Dialog
        fullScreen
        open={open_dialog_edit}
        onClose={() => set_open_dialog_edit(false)}
        scroll={"paper"}
      >
        <EditChiTieu
          onSaveClick={() => {
            set_open_dialog_edit(false);
            setTimeout(() => {
              get_data(date_month, date_year);
            }, 500);
          }}
          onDiscardClick={() => set_open_dialog_edit(false)}
          data={data_edit}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={open_dialog}
        onClose={() => set_open_dialog(false)}
        scroll={"paper"}
      >
        <AddChiTieu
          onDiscardClick={() => set_open_dialog(false)}
          onSaveClick={() => {
            set_open_dialog(false);
            setTimeout(() => {
              get_data(date_month, date_year);
            }, 500);
          }}
        />
      </Dialog>
      <Box
        style={{ height: 100, backgroundColor: theme.palette.color_map.gray }}
      ></Box>
    </Box>
  );
}

const style_detail = {
  add_icon: {
    position: "fixed",
    bottom: 40,
    right: "42%",
  },
};

export default ChiTieu;
