import * as React from "react";
import axios from "axios";
import render_number from "./util";
import { Card, Box, Divider, Typography } from "@mui/material";
import theme from "./theme";
// import data_const from "./constant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { LoginContext } from "./AppContext";

ChartJS.register(ArcElement, Tooltip, Legend);

function ChiTieu(props) {
  const login = React.useContext(LoginContext);

  let date_now = new Date();
  let date_month_init = date_now.getMonth() + 1;
  let date_year_init = date_now.getFullYear();

  const [date_month, set_date_month] = React.useState(date_month_init);
  const [date_year, set_date_year] = React.useState(date_year_init);

  const [data, setData] = React.useState(null);
  const [dataMonth, setDataMonth] = React.useState(null);

  const [data_donut, set_data_donut] = React.useState(null);
  const [data_donut_in, set_data_donut_in] = React.useState(null);

  const config_axios = (url_str) => {
    return {
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT + "report" + url_str,
      headers: { Authorization: login.token },
    };
  };

  const get_data = () => {
    axios(config_axios(""))
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.hasOwnProperty("status")) {
            if (error.response.status == 401) {
              login.set_token(null);
            }
          }
        } else {
          console.log(error);
        }
      });
  };

  const get_data_month = (date_year_input) => {
    axios(config_axios("/" + date_year_input))
      .then((data) => {
        // console.log(data);
        if (data.data.data != null) {
          setDataMonth(data.data);
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.hasOwnProperty("status")) {
            if (error.response.status == 401) {
              login.set_token(null);
            }
          }
        } else {
          console.log(error);
        }
      });
  };

  const get_data_detail = (date_month, date_year) => {
    axios(config_axios("/" + date_year + "/" + date_month))
      .then((data) => {
        if (data.data.data != null) {
          let datax = data.data;

          let money_out_arr = [];
          let label_arr = [];

          let money_in_arr = [];
          let label_in_arr = [];

          datax.data.map((item) => {
            if (item.c_type == "OUT") {
              money_out_arr.push(item.sum_money);
              label_arr.push(
                item.c_name + ": " + render_number(item.sum_money)
              );
            } else {
              money_in_arr.push(item.sum_money);
              label_in_arr.push(
                item.c_name + ": " + render_number(item.sum_money)
              );
            }
          });
          set_data_donut({
            labels: label_arr,
            datasets: [
              {
                label: "Chi tiêu",
                data: money_out_arr,
                backgroundColor: theme.palette.list_backgroundColor,
                borderColor: theme.palette.list_borderColor,
                borderWidth: 3,
              },
            ],
          });

          set_data_donut_in({
            labels: label_in_arr,
            datasets: [
              {
                label: "Thu nhập",
                data: money_in_arr,
                backgroundColor: theme.palette.list_backgroundColor,
                borderColor: theme.palette.list_borderColor,
                borderWidth: 3,
              },
            ],
          });
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.hasOwnProperty("status")) {
            if (error.response.status == 401) {
              login.set_token(null);
            }
          }
        } else {
          console.log(error);
        }
      });
  };

  React.useEffect(() => {
    get_data();
    get_data_month(date_year);
    get_data_detail(date_month, date_year);
  }, []);

  const render_nam = (item) => {
    return (
      <Box>
        <Box
          style={
            date_year == item.date_year
              ? style_detail.box_5_nam_active
              : style_detail.box_5_nam
          }
          onClick={() => {
            set_date_month(0);
            set_date_year(item.date_year);

            get_data_month(item.date_year);
            get_data_detail(0, item.date_year);
          }}
        >
          <Box style={{ flex: 1, justifyContent: "center", display: "flex" }}>
            {item.date_year}
          </Box>
          <Box style={style_detail.box_4}>{render_number(item.in)}</Box>
          <Box style={style_detail.box_1}>{render_number(item.out)}</Box>
          <Box style={style_detail.box_balance}>
            {render_number(item.balance)}
          </Box>

          <ExpandMoreIcon />
        </Box>
        <Divider />
      </Box>
    );
  };

  const render_header = () => {
    return (
      <Box style={style_detail.header_box}>
        <Box style={style_detail.box_5}>
          <Box style={{ flex: 1, justifyContent: "center" }}>Ngày</Box>
          <Box style={style_detail.box_3}>
            <Typography style={style_detail.text_money_2}>Thu nhập</Typography>
          </Box>

          <Box style={style_detail.box_2}>
            <Typography style={style_detail.text_money}>Chi tiêu</Typography>
          </Box>

          <Box style={style_detail.box_balance}>
            <Typography style={style_detail.text_money}>Số dư</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const render_thang = (itemx, max_in, max_out) => {
    return (
      <Box
        style={
          itemx.date_month == date_month ? { backgroundColor: "#CCFF90" } : null
        }
      >
        <Box
          onClick={() => {
            set_date_month(itemx.date_month);
            get_data_detail(itemx.date_month, date_year);
          }}
          style={style_detail.box_5}
        >
          <Box style={{ flex: 1, justifyContent: "center", display: "flex" }}>
            Th {itemx.date_month}
          </Box>
          <Box style={style_detail.box_3}>
            <Box
              style={{
                flex: itemx.money_in / max_in,
                backgroundColor: "#AED581",
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            ></Box>
            <Typography style={style_detail.text_money_2}>
              {render_number(itemx.money_in)}
            </Typography>
          </Box>

          <Box style={style_detail.box_2}>
            <Box
              style={{
                flex: itemx.money_out / max_out,
                backgroundColor: "#F48FB1",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            ></Box>
            <Typography style={style_detail.text_money}>
              {render_number(itemx.money_out)}
            </Typography>
          </Box>

          <Box style={style_detail.box_balance}>
            <Typography style={style_detail.text_money}>
              {render_number(itemx.balance)}
            </Typography>
          </Box>

          <ExpandMoreIcon />
        </Box>
        <Divider />
      </Box>
    );
  };

  const options_out = {
    plugins: {
      maintainAspectRatio: false,
      legend: {
        position: "left",
        labels: {
          boxWidth: 10,
        },
      },
      title: {
        display: true,
        text: "Chi tiêu",
      },
    },
  };

  const options_in = {
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 10,
        },
      },
      title: {
        display: true,
        text: "Thu nhập",
      },
    },
  };

  // console.log(data_donut_in);

  return (
    <Box
      style={{ backgroundColor: theme.palette.color_map.gray, maxWidth: 600 }}
    >
      <Card style={{ margin: 10 }}>{render_header()}</Card>

      <Card style={{ margin: 10 }}>
        {data == null
          ? null
          : data.data.map((item) => (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                {render_nam(item)}
              </Box>
            ))}
      </Card>

      <Card style={{ margin: 10 }}>
        {dataMonth == null
          ? null
          : dataMonth.data.map((item) => (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {render_thang(item, dataMonth.max_in, dataMonth.max_out)}
              </Box>
            ))}
      </Card>

      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: theme.palette.color_map.gray,
        }}
      >
        {data_donut != null ? (
          data_donut.labels.length != 0 ? (
            <Card>
              <Doughnut data={data_donut} options={options_out} />
            </Card>
          ) : null
        ) : null}
      </Box>
      <Box
        style={{ padding: 10, backgroundColor: theme.palette.color_map.gray }}
      >
        {data_donut_in != null ? (
          data_donut_in.labels.length != 0 ? (
            <Card>
              <Doughnut data={data_donut_in} options={options_in} />
            </Card>
          ) : null
        ) : null}
      </Box>

      <Box
        style={{ height: 50, backgroundColor: theme.palette.color_map.gray }}
      ></Box>
    </Box>
  );
}

const style_detail = {
  header_box: {
    backgroundColor: "white",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
    paddingTop: 5,
  },
  text_money: { position: "absolute", marginLeft: 10 },
  text_money_2: { position: "absolute", marginRight: 10 },
  box_1: {
    flex: 1,
    justifyContent: "flex-start",
    display: "flex",
    paddingLeft: 10,
  },
  box_4: {
    flex: 1,
    justifyContent: "flex-end",
    display: "flex",
    paddingRight: 10,
  },
  box_5: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
  },
  box_5_nam: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    // backgroundColor: "",
  },

  box_5_active: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#B0BEC5",
  },
  box_5_nam_active: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#CCFF90",
  },

  box_2: {
    flex: 2,
    justifyContent: "flex-start",
    display: "flex",
  },
  box_balance: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex",
  },
  box_3: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex",
  },
  add_icon: {
    position: "fixed",
    bottom: 40,
    right: "42%",
  },
  box_to: {
    backgroundColor: "white",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
  chon_nam: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    paddingRight: 10,
  },
};

export default ChiTieu;
