import * as React from "react";
import { Typography, Box, Divider, CardContent, Card } from "@mui/material";
import render_number from "../util";
import theme from "../theme";

export default function ProTip(props) {
  let data = props.data;

  return (
    <Card style={{ marginLeft: 10, marginRight: 10, maxWidth: 600 }}>
      <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography style={theme.palette.congtien}>Tổng thu</Typography>
          <Typography style={theme.palette.congtien}>
            {render_number(data.money_in_trong_thang)}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>Thu nhập</Typography>
          <Typography>
            {render_number(
              data.money_in_trong_thang - data.money_out_trong_thang
            )}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography style={theme.palette.trutien}>Tổng chi</Typography>
          <Typography style={theme.palette.trutien}>
            {render_number(data.money_out_trong_thang)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
