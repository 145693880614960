import * as React from "react";
import theme from "./theme";
import SelectTypeReport from "./component/SelectTypeReport";
import BaoCaoThang from "./BaoCaoThang";
import BaoCaoLoai from "./BaoCaoLoai";
import { Box } from "@mui/material";

function ChiTieu(props) {
  const [type_report, set_type_report] = React.useState(0);

  const render_type = () => {
    if (type_report == 0) {
      return <BaoCaoThang />;
    } else if (type_report == 1) {
      return <BaoCaoLoai />;
    } else {
      return null;
    }
  };

  return (
    <Box
      style={{ backgroundColor: theme.palette.color_map.gray, paddingTop: 10 }}
    >
      <Box style={{ maxWidth: 600 }}>
        <SelectTypeReport
          type_report={type_report}
          onChangeValue={(new_value) => set_type_report(new_value)}
        />
      </Box>
      {render_type()}
    </Box>
  );
}

export default ChiTieu;
