import axios from "axios";
import * as React from "react";
import {
  TextField,
  Card,
  Typography,
  Box,
  Tab,
  Tabs,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import data_const from "./constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RenderCategory from "./RenderCategory";

import danh_muc_chi_tieu from "./Category";
import danh_muc_thu_nhap from "./Category_thunhap";
import render_number from "./util";

import SaveBtn from "./component/SaveBtn";

const ExampleCustomInput = (props) => (
  <Button variant="outlined" fullWidth onClick={() => props.onClick()}>
    <Typography>{props.value}</Typography>
  </Button>
);

import { LoginContext } from "./AppContext";

export default function App(props) {
  const login = React.useContext(LoginContext);
  let data = props.data;
  let value_tab_init = data_const.chi_tieu;
  let danh_muc_init = danh_muc_chi_tieu;

  let so_tien_init = render_number(data.money);
  if (data.c_type == data_const.TRANSACTION_IN) {
    value_tab_init = data_const.thu_nhap;
    danh_muc_init = danh_muc_thu_nhap;
  }
  const [value_tab, setValue_tab] = React.useState(value_tab_init);
  const [danh_muc, set_danh_muc] = React.useState(danh_muc_init);
  const [so_tien, set_so_tien] = React.useState(so_tien_init);

  let category_init = {
    id: data.c_id,
    name: data.c_name,
    type: data.c_type,
  };
  const [category, set_category] = React.useState(category_init);

  let startDate_init = new Date();

  try {
    startDate_init = new Date(
      data.date_month + "/" + data.date_day + "/" + data.date_year
    );
  } catch (error) {
    console.log(error.message);
  }

  const [startDate, setStartDate] = React.useState(startDate_init);
  const [note, setNote] = React.useState(data.note);
  const [showDanhmuc, set_showDanhmuc] = React.useState(false);

  const edit_chi_tieu = () => {
    var data_body = {
      date_year: startDate.getFullYear(),
      date_month: startDate.getMonth() + 1,
      date_day: startDate.getDate(),
      money: Number(so_tien.replaceAll(/,/g, "")),
      note: note,
      category_id: category.id,
    };

    var config = {
      method: "patch",
      url: process.env.REACT_APP_API_ENDPOINT + "transaction/" + data.t_id,
      headers: { Authorization: login.token },
      data: data_body,
    };

    axios(config).then((data) => {
      if (!data.data.error) {
        props.onSaveClick();
      }
    });
  };

  const delete_chitieu = () => {
    var config = {
      method: "delete",
      url:
        process.env.REACT_APP_API_ENDPOINT + "transaction/" + props.data.t_id,
      headers: { Authorization: login.token },
    };

    axios(config).then((data) => {
      if (!data.data.error) {
        props.onSaveClick();
      }
    });
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 10,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={() => delete_chitieu()}
        >
          DELETE
        </Button>
      </Box>
      <Box>
        <Tabs
          value={value_tab}
          onChange={(event, newValue) => {
            setValue_tab(newValue);
            set_category(null);

            if (newValue == data_const.chi_tieu) {
              set_danh_muc(danh_muc_chi_tieu);
            } else {
              set_danh_muc(danh_muc_thu_nhap);
            }
          }}
          variant="fullWidth"
          indicatorColor="primary"
        >
          <Tab label="Thu nhập" value={data_const.thu_nhap} />
          <Tab label="Chi tiêu" value={data_const.chi_tieu} />
        </Tabs>
        <Divider />
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Số tiền</Typography>
        <TextField
          autoFocus
          style={{ flex: 1 }}
          fullWidth
          name="So tien"
          placeholder="Số tiền"
          value={so_tien}
          onChange={(e) => {
            let a = e.target.value;
            a = a.replaceAll(/,/g, "");
            a = Number(a)
              .toFixed(1)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .replace(".0", "");
            set_so_tien(a);
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
        />
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Danh mục chi tiêu</Typography>
        <Box style={{ flex: 1 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => set_showDanhmuc(true)}
          >
            <Typography>
              {category == null ? "Lựa chọn" : category.name}
            </Typography>
          </Button>
        </Box>
      </Box>

      {showDanhmuc
        ? danh_muc.map((itemx) => {
            return (
              <Card style={{ margin: 5 }}>
                <Grid container>
                  {itemx.map((item) => {
                    return (
                      <Grid
                        xs={5}
                        sx={style_detail.danhmucItem}
                        onClick={() => {
                          set_showDanhmuc(false);
                          set_category({
                            name: item.name,
                            id: item.id,
                            type: item.category,
                          });
                        }}
                      >
                        <Box style={{ paddingRight: 10 }}>
                          <Box style={{ paddingRight: 10 }}>
                            {RenderCategory(item.id)}
                          </Box>
                        </Box>
                        <Typography>{item.name}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Card>
            );
          })
        : null}

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Ngày</Typography>
        <Box style={{ flex: 1 }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<ExampleCustomInput />}
          />
        </Box>
      </Box>

      <Box style={style_detail.boxItem}>
        <Typography style={{ flex: 1 }}>Ghi chú</Typography>
        <TextField
          style={{ flex: 1 }}
          fullWidth
          name="Note"
          placeholder="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Box>

      <SaveBtn
        onClickSave={() => edit_chi_tieu()}
        onClickCancle={() => props.onDiscardClick()}
        showSave={category}
      />
    </Box>
  );
}

const style_detail = {
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 10,
  },
  danhmucItem: {
    flexDirection: "row",
    display: "flex",
    margin: 1,
    padding: 1,
  },
};
