import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";
import BuildTwoToneIcon from "@mui/icons-material/BuildTwoTone";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import CellWifiIcon from "@mui/icons-material/CellWifi";
import HouseIcon from "@mui/icons-material/House";
import BuildIcon from "@mui/icons-material/Build";
import DevicesIcon from "@mui/icons-material/Devices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import SchoolIcon from "@mui/icons-material/School";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import HikingIcon from "@mui/icons-material/Hiking";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import PercentIcon from "@mui/icons-material/Percent";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import SavingsTwoToneIcon from "@mui/icons-material/SavingsTwoTone";
import AutoAwesomeTwoToneIcon from "@mui/icons-material/AutoAwesomeTwoTone";
import FastfoodTwoToneIcon from "@mui/icons-material/FastfoodTwoTone";
import OpacityTwoToneIcon from "@mui/icons-material/OpacityTwoTone";
import PhoneAndroidTwoToneIcon from "@mui/icons-material/PhoneAndroidTwoTone";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import EmojiFoodBeverageTwoToneIcon from "@mui/icons-material/EmojiFoodBeverageTwoTone";
import LocalGasStationTwoToneIcon from "@mui/icons-material/LocalGasStationTwoTone";
import MopedTwoToneIcon from "@mui/icons-material/MopedTwoTone";
import CarRentalTwoToneIcon from "@mui/icons-material/CarRentalTwoTone";
import FaceRetouchingNaturalTwoToneIcon from "@mui/icons-material/FaceRetouchingNaturalTwoTone";
import LocalGroceryStoreTwoToneIcon from "@mui/icons-material/LocalGroceryStoreTwoTone";
export default function App(props) {
  if (props == 26) {
    return <AutoAwesomeTwoToneIcon />;
  } else if (props == 27) {
    return <PercentIcon />;
  } else if (props == 25) {
    return <MonetizationOnIcon />;
  } else if (props == 1) {
    return <FastfoodTwoToneIcon />;
  } else if (props == 2) {
    return <EmojiFoodBeverageTwoToneIcon />;
  } else if (props == 3) {
    return <ReceiptLongIcon />;
  } else if (props == 4) {
    return <ElectricalServicesIcon />;
  } else if (props == 5) {
    return <OpacityTwoToneIcon />;
  } else if (props == 6) {
    return <PhoneAndroidTwoToneIcon />;
  } else if (props == 7) {
    return <CellWifiIcon />;
  } else if (props == 8) {
    return <HouseIcon />;
  } else if (props == 9) {
    return <MopedTwoToneIcon />;
  } else if (props == 10) {
    return <CarRentalTwoToneIcon />;
  } else if (props == 11) {
    return <LocalGasStationTwoToneIcon />;
  } else if (props == 12) {
    return <BuildTwoToneIcon />;
  } else if (props == 13) {
    return <LocalMallTwoToneIcon />;
  } else if (props == 14) {
    return <CheckroomIcon />;
  } else if (props == 15) {
    return <DevicesIcon />;
  } else if (props == 16) {
    return <FavoriteTwoToneIcon />;
  } else if (props == 17) {
    return <HikingIcon />;
  } else if (props == 18) {
    return <MedicalInformationIcon />;
  } else if (props == 19) {
    return <AttachMoneyIcon />;
  } else if (props == 20) {
    return <FamilyRestroomIcon />;
  } else if (props == 21) {
    return <ChildFriendlyIcon />;
  } else if (props == 22) {
    return <BuildIcon />;
  } else if (props == 23) {
    return <SchoolIcon />;
  } else if (props == 24) {
    return <AttachMoneyIcon />;
  } else if (props == 31) {
    return <SavingsTwoToneIcon />;
  } else if (props == 32) {
    return <TrendingUpIcon />;
  } else if (props == 33) {
    return <LocalAtmIcon />;
  } else if (props == 34) {
    return <TrendingDownIcon />;
  } else if (props == 35) {
    return <LocalGroceryStoreTwoToneIcon />;
  } else if (props == 36) {
    return <FaceRetouchingNaturalTwoToneIcon />;
  } else {
    return <MonetizationOnTwoToneIcon />;
  }
}
