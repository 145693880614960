import * as React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import theme from "../theme";
import RenderCategory from "../RenderCategory";
import render_number from "../util";
import data_const from "../constant";

export default function App(props) {
  let data = props.data;

  const renderDate = (item, index) => {
    if (index === 0) {
      return (
        <Card style={{ ...style_detail.box3, flex: 1 }}>
          <Typography>
            {item.date_day}/{item.date_month}
          </Typography>
        </Card>
      );
    }
    if (data[index - 1].date_day == data[index].date_day) {
      return <Box style={{ flex: 1 }}></Box>;
    } else {
      return (
        <Card style={{ ...style_detail.box3, flex: 1 }}>
          <Typography>
            {item.date_day}/{item.date_month}
          </Typography>
        </Card>
      );
    }
  };

  return data.map((item, index) => (
    <Box
      style={{
        marginLeft: 10,
        marginRight: 10,
        marginTop: 5,
        maxWidth: 600,
        display: "flex",
      }}
    >
      {renderDate(item, index)}

      <Card style={{ flex: 6 }} onClick={() => props.onClickEdit(item)}>
        <CardContent>
          <Box style={style_detail.box4}>
            <Box style={style_detail.box3}>{RenderCategory(item.c_id)}</Box>
            <Box style={style_detail.box2}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Typography>{item.c_name}</Typography>
                <Typography style={style_detail.textnote}>
                  {item.note}
                </Typography>
              </Box>
            </Box>
            <Box style={style_detail.box1}>
              <Typography
                style={
                  item.c_type == data_const.TRANSACTION_IN
                    ? theme.palette.congtien
                    : theme.palette.trutien
                }
              >
                {render_number(item.money)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  ));
}

const style_detail = {
  box1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 2,
  },
  box2: {
    flex: 5,
    display: "flex",
    alignItems: "center",
  },
  box3: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  box4: {
    height: 30,
    display: "flex",
    justifyContent: "space-between",
  },
  textnote: {
    fontSize: 12,
    color: theme.palette.color_map.gray400,
  },
};
